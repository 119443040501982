// Core
import React, { useRef, useState, useEffect } from "react"
import { gsap } from "gsap"
import { Draggable } from "gsap/Draggable"
import { InertiaPlugin } from "gsap/InertiaPlugin"

import { isMobile } from "react-device-detect"
import Hammer from "react-hammerjs"

// InView detector
import { InView } from "react-intersection-observer"

// Components
// import Button from "../button/button"
import JsonText from "../_jsonText"
import ScrollSection from "../locomotiveScroll/scrollSection"
import SplitText from "../split-text/split-text"

// Styles
import styles from "./text-multi-animation.module.scss"

//Animations

import CoCreation from "../anim-co-creation/animation"
import Ent from "../anim-ent/animation"
import Special from "../anim-special/animation"
import Ideation from "../anim-ideation/animation"

//SVG
import Drag from "./../../../static/images/svg/drag.inline.svg"
import DragCircle from "./../../../static/images/svg/drag-circle.inline.svg"
import Circle from "./../../../static/images/svg/circle.inline.svg"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(Draggable)
  gsap.registerPlugin(InertiaPlugin)
}

const TextAnimation = props => {
  const { templateData } = props,
    { bgColour } = props,
    { textAlignment } = props,
    classString = `copy copy--s ${styles.copy}`

  const [currentActive, setCurrentActive] = useState(1),
    max = 4

  const dragger = useRef()
  const dragPrompt = useRef()
  const dragIcon = useRef(),
    textRef = useRef()

  const handleSwipe = e => {
    if (isMobile) {
      if (e.direction === 4) {
        //left
        console.log("mobile left swipe")
        if (currentActive === 1) return
        setCurrentActive(currentActive - 1)
        // textRef.current.querySelector("ul").style.height =
        //   textRef.current.querySelectorAll("li")[currentActive - 1]
        //     .scrollHeight + `px`
      }
      if (e.direction === 2) {
        //right
        console.log("mobile right swipe")
        if (currentActive === max) return
        setCurrentActive(currentActive + 1)
        // textRef.current.querySelector("ul").style.height =
        //   textRef.current.querySelectorAll("li")[currentActive].scrollHeight +
        //   `px`
      } else return
    } else return
  }

  useEffect(() => {
    if (window.innerWidth > 1024) {
      textRef.current.querySelector("ul").style.maxHeight =
        textRef.current.querySelectorAll("li")[currentActive - 1].scrollHeight +
        60 +
        `px`

      if (window.scroll) {
        if (window.scroll.update) {
          setTimeout(() => {
            window.scroll.update()
          }, 601)
        }
      }
    } else {
      textRef.current.querySelector("ul").style.maxHeight =
        textRef.current.querySelectorAll("li")[currentActive - 1].scrollHeight +
        `px`
    }
  }, [currentActive])

  useEffect(() => {
    const { current } = dragger
    const rotationSnap = 90

    if (current) {
      Draggable.create(current, {
        type: "rotation",
        inertia: true,
        trigger: dragIcon.current,
        dragResistance: 0.05,
        throwResistance: 50000,
        maxDuration: 0.2,
        snap: function (endValue) {
          //rotation value between 1 and 4 for updating the data attribute
          if (
            parseInt(
              (Math.round(endValue / rotationSnap) * rotationSnap) % 360,
              10
            ) === 90
          ) {
            setCurrentActive(2)
          } else if (
            parseInt(
              (Math.round(endValue / rotationSnap) * rotationSnap) % 360,
              10
            ) === 180
          ) {
            setCurrentActive(3)
          } else if (
            parseInt(
              (Math.round(endValue / rotationSnap) * rotationSnap) % 360,
              10
            ) === 270
          ) {
            setCurrentActive(4)
          } else if (
            parseInt(
              (Math.round(endValue / rotationSnap) * rotationSnap) % 360,
              10
            ) === 0
          ) {
            setCurrentActive(1)
          }
          return Math.round(endValue / rotationSnap) * rotationSnap //full rotation value
        },
      })
    }
  })

  let co = <CoCreation id={1} currentActive={currentActive} />,
    special = <Special id={2} currentActive={currentActive} />,
    ideation = <Ideation id={3} currentActive={currentActive} />,
    ent = <Ent id={4} currentActive={currentActive} />

  // const Text = ({ children }) => (
  //   <SplitText classString={classString}>{children}</SplitText>
  // )

  // const options = {
  //   renderNode: {
  //     [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  //   },
  // }

  return (
    <ScrollSection>
      <section
        className={styles.textanim}
        data-colour-scheme={bgColour}
        data-text-alignment={textAlignment}
        data-active={currentActive}
      >
        <div className="container">
          <InView triggerOnce={true} threshold={0.2}>
            {({ inView, ref, entry }) => (
              <div
                className={`cols flex ${styles.textanimcols}`}
                ref={ref}
                inview={inView.toString()}
              >
                <div className={`col m12 t4 ${styles.animation}`} data-scroll>
                  <div className={styles.animationwrap}>
                    <div className={styles.animationContainer}>
                      {co}
                      {special}
                      {ideation}
                      {ent}
                    </div>
                    <DragCircle />
                    <Circle />
                    <span className={styles.dragPrompt} ref={dragPrompt}>
                      Drag
                    </span>
                    <span className={styles.drag} ref={dragger}>
                      <span
                        className={styles.dragicon}
                        ref={dragIcon}
                        aria-hidden="true"
                        onMouseEnter={() => {
                          dragPrompt.current.classList.add("hidden")
                        }}
                      >
                        <Drag />
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  className={`col m12 t10 d4 ${styles.text}`}
                  data-scroll
                  ref={textRef}
                >
                  <Hammer onSwipe={handleSwipe}>
                    <ul>
                      {templateData.textDraggableAnimation.textItem.map(
                        (item, index) => (
                          <li key={index} className={styles.textitem}>
                            <SplitText classString={`h3 ${styles.heading}`}>
                              {item.itemTitle}
                            </SplitText>
                            <JsonText
                              content={item.copy}
                              split={true}
                              classString={classString}
                            />
                          </li>
                        )
                      )}
                      <span className={`${styles.counter}`}>
                        <span className={styles.currentcount}>
                          {currentActive}
                        </span>{" "}
                        / 4
                      </span>
                    </ul>
                  </Hammer>
                  <div className={styles.marker}>
                    {templateData.textDraggableAnimation.textItem.map(
                      (item, index) => (
                        <span key={index}>
                          <span></span>
                        </span>
                      )
                    )}
                  </div>
                </div>
              </div>
            )}
          </InView>
        </div>
      </section>
    </ScrollSection>
  )
}

export default TextAnimation
