// Core
import React from "react"

// InView detector
import { InView } from "react-intersection-observer"

// Components
import Button from "../button/button"
import ScrollSection from "../locomotiveScroll/scrollSection"
import SplitText from "../split-text/split-text"
import SplitHeading from "../split-heading/split-heading"
import Animation from "../value-creation-animation/value-creation-animation"

// Styles
import styles from "./text-animation.module.scss"

//Temp img
// import animPlaceholder from "./../../images/home/anim.png"

const TextAnimation = props => {
  const { copy } = props,
    { sub } = props,
    { bgColour } = props,
    { buttonProps } = props,
    { textAlignment } = props,
    classString = `copy copy--m ${styles.copy}`

  let buttonTheme

  if (bgColour === "#172138") {
    buttonTheme = "light"
  } else {
    buttonTheme = "dark"
  }

  let btnProps = {
    buttonProps,
    buttonTheme,
  }

  let anim = <Animation />

  return (
    <ScrollSection>
      <section
        className={styles.textanim}
        data-colour-scheme={bgColour}
        data-text-alignment={textAlignment}
      >
        <div className="container">
          <div className={`cols flex ${styles.textanimcols}`}>
            <div
              className={`col m12 t5 ${styles.animation}`}
              data-scroll
              data-scroll-speed={0.5}
              data-scroll-delay={1}
            >
              <div className={styles.animationwrap}>{anim}</div>
            </div>
            <InView triggerOnce={true} threshold={0.2}>
              {({ inView, ref, entry }) => (
                <div className={`col m12 t6 ${styles.text}`}>
                  <div
                    ref={ref}
                    inview={inView.toString()}
                    data-scroll
                    data-scroll-speed={1}
                  >
                    <SplitHeading classString={`subheading`}>
                      {sub}
                    </SplitHeading>
                    <SplitText classString={classString}>{copy}</SplitText>
                    <Button {...btnProps} />
                  </div>
                </div>
              )}
            </InView>
          </div>
        </div>
      </section>
    </ScrollSection>
  )
}

export default TextAnimation
