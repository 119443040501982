import React, { useContext, useEffect } from "react"
import { ContextDispatch } from "./../../context/Context"
import { graphql } from "gatsby"

//Components
import Footer from "../../components/footer/footer"
import Hero from "../../components/large-hero/large-hero"
import NextPage from "../../components/next-page/next-page"
import TextAnimation from "../../components/text-animation-value-creation/text-animation"
import TextMultiAnimation from "../../components/text-multi-animation/text-multi-animation"
import Carousel from "../../components/carousel/carousel"
import SEO from "../../components/seo/seo"

const Template2 = props => {
  const page = props,
    templateData = page.data.contentfulTemplate2

  const storeDispatch = useContext(ContextDispatch)

  useEffect(() => {
    const header = () => {
      storeDispatch({ type: "HEADER_DARK" })
    }

    header()
  }, [storeDispatch])
  let dragblock, carouselblock, animationblock, nextpageblock

  if (templateData.textDraggableAnimation) {
    dragblock = (
      <TextMultiAnimation
        bgColour={`#172138`}
        textAlignment={`left`}
        templateData={templateData}
      />
    )
  }

  if (templateData.textAnimationCopy) {
    animationblock = (
      <TextAnimation
        bgColour={`#f9f8f2`}
        textAlignment={`left`}
        sub={templateData.textAnimationHeading}
        copy={templateData.textAnimationCopy.textAnimationCopy}
        buttonProps={templateData.textAnimationButton}
      />
    )
  }

  if (templateData.nextPage) {
    nextpageblock = (
      <NextPage
        heading={templateData.nextPage.heading}
        title={templateData.nextPage.linkTitle}
        url={`/` + templateData.nextPage.page.slug}
      />
    )
  }

  if (templateData.carousel.carouselTitle) {
    carouselblock = (
      <Carousel
        bgColour={`#2431b3`}
        title={templateData.carousel.carouselTitle}
        mainImg={templateData.carousel.carouselMainImage}
        vimeo={templateData.carousel.carouselVimeo}
        items={templateData.carousel}
      />
    )
  }

  return (
    <React.Fragment>
      <SEO
        title={templateData.seoData.seoTitle}
        image={templateData.seoData.seoImage}
        description={templateData.seoData.seoDescription}
      />
      <Hero heroData={templateData} />
      {dragblock}
      {carouselblock}
      {animationblock}
      {nextpageblock}
      <Footer />
    </React.Fragment>
  )
}

export default Template2

export const focusPageQuery = graphql`
  query template2PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulTemplate2(slug: { eq: $slug }) {
      ...Template2PageQuery
    }
  }
`
